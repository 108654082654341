<template>
  <div>
    <!--  -->
    <div class="zhfwtop">
      <div class="indeximgtopcontent">
         <div class="indexlogo"></div>
      <div class="indexlist">
          <ul class="indexlistul">
            <li  @click="go('/home')"><span>首页</span></li>
            <li  class="indexli2">
              <span >产品解决方案</span>
                <ul class="menu">
                  <li  @click="go('/cpjjfa/zhfw')">智慧法务</li>
                  <li  @click="go('/cpjjfa/zhcg')">智慧采购</li>
                  <li  @click="go('/cpjjfa/zhrz')">智慧人资</li>
                  <li @click="go('/cpjjfa/zhsj')">智慧审计</li>
                  <li  @click="go('/cpjjfa/bgxt')">办公协同</li>
                  <li @click="go('/cpjjfa/dsj')">大数据分析-BI技术平台</li>
                </ul>
            </li>
            <li @click="go('/jszc')"><span>技术支持</span></li>
            <li  @click="go('/yhal')"><span class="active2">用户案例</span></li>
            <li  @click="go('/gywm')"><span>关于我们</span></li>
          </ul>
      </div>
      </div>

      <div class="zhfwbutton"></div>
    </div>
    <!--   -->
    <div class="gdyh">
      <div class="gdyhcontent">
        <div class="gdyhcontenttop">流程自动化机器人系统，RPA应用规范建设，加速业务流程，降低运营成本，提高业务化能力，让员工创新效用意识，提升企业创新能力。
          RPA流程实现目标：人、人合适，正常企业查询；个人预警情况数据；一次截屏或多截屏个人黑灰数据；一次截屏或截屏根据客户号、身份号数据导出数据…
         OCR模块实现目标：发票数据；发票验证</div>
         <div class="gdyhbottompng"></div>
      </div>

    </div>
    <!--  -->
    <tabar-bottom></tabar-bottom>
  </div>
</template>

<script>
import TabarBottom from '../../../components/TabarBottom.vue'
  export default {
    components:{TabarBottom},
    methods:{
      go(path){
        this.$router.push({
          path:path
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.indeximgtopcontent{
  width: 1223px;
  height: 46px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // border: 1px solid red;
}
//
.gdyh{
  height: 998px;
  .gdyhcontent{
    width: 1200px;
    height: inherit;
    margin: 0 auto;
    padding-top: 60px;
    .gdyhcontenttop{
      text-indent: 2rem;
      width: 1200px;
      height: 111px;
      font-size: 18px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      color: #282A2E;
    }
    .gdyhbottompng{
      width: 1200px;
      height: 704px;
      background: url('../../.././assets/gdyhbottompng.png') no-repeat center 0;
      margin-top: 40px;
    }
  }
}
//
.active{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
}
.active2{
  display: inline-block;
  color: #FFA000;
  line-height: 30px;
  border-bottom: 1px solid #FFA000;
}
.zhfwtop{
  // width: 1903px;
  height: 500px;
  background: url('../../.././assets/yhal_gyyqback.png') no-repeat center 0;
  margin: 0 auto;
  padding-top: 34px;
  .indexlogo{
  width: 220px;
  height: 52px;
  background: url('../../.././assets/logo.png') no-repeat center 0;
}
.zhfwbutton{
  float: left;
  width: 373px;
  height: 175px;
  margin-left: 774px;
  margin-top: 90px;
}
.zhfwindc{
  text-align: center;
  float: left;
  width: 1100px;
  height: 51px;
  font-size: 16px;
  margin-top: 20px;
  margin-left: 432px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
.indexlist{
  width: 632px;
  font-size: 14px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  .indexlistul{
    display: flex;
    text-align: center;
    li{
      cursor: pointer;
      width: 160px;
      line-height: 45px;
      span:hover{
        cursor: pointer;
        text-align: center;
        color: #FFA000;
      }
    }

  }
}
//
.menu{
  position: absolute;
  display: none;
  top: 45px;
  background: #fff;
  color: #282A2E;
  padding-left: 20px;
  text-align: left;
  left: 16px;

}
.menu li:hover{
  color: #FFA000;
}
.indexli2{
  position: relative;
}
.indexli2:hover .menu{
  display: block;
}
}
</style>
